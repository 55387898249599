export enum Locale {
	cs = "cs",
}

export type Localized<Type> = Record<Locale, Type>;

export type LocalizedString = Localized<string>;

export type LocalizedStringArray = Localized<string[]>;

export type LocalizedBoolean = Localized<boolean>;

export type LocalizedID = Localized<string>;
